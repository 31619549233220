import React from 'react'
import { connect } from 'react-redux'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import { AppBar, Toolbar, Typography } from '@mui/material'
import { IStore } from 'reducers@app/index'
import { ITranslation } from 'model@app/translation'

type Props = {
  children?: any
  translation: ITranslation
}

function AppDrawerLogin(props: Props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AppBar color="default">
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.title}>
            {props.translation.logo}
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.contentStyle}>{props.children}</main>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      cursor: 'pointer',
    },
    contentStyle: {
      maxWidth: '800px',
      width: '90vw',
      margin: '0 auto',
      display: 'block',
      backgroundColor: theme.palette.background.default,
      paddingTop: theme.spacing(1),
      height: 'calc(100% - 56px)',
      marginTop: 56,
      [theme.breakpoints.up('sm')]: {
        height: 'calc(100% - 64px)',
        marginTop: 64,
      },
    },
  }),
)

const mapStateToProps = (state: IStore) => ({
  translation: state.translation,
})

export default connect(mapStateToProps)(AppDrawerLogin)
