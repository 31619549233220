import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import AppDrawerLogin from 'containers@app/organisms/AppDrawerLogin/AppDrawerLogin'
import Login from 'containers@app/template/Login'

const LoginPage = () => (
  <>
    <CssBaseline />
    <AppDrawerLogin />
    <Login />
  </>
)

export default LoginPage
