import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useSetRecoilState } from 'recoil'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { Theme } from '@mui/material/styles'
import { makeStyles, createStyles } from '@mui/styles'
import { Button, Typography } from '@mui/material'
import { IStore } from 'reducers@app/index'
import { IArchitecture } from 'model@app/architecture'
import { ITranslation } from 'model@app/translation'
import { fetcher } from 'lib@app/fetcher'
import { snackbarState } from '@store/atoms/snackbar'

type Props = {
  architecture: IArchitecture
  translation: ITranslation
}

function Login(props: Props) {
  const classes = useStyles()
  const router = useRouter()
  const setSnackbar = useSetRecoilState(snackbarState)
  const [canShowButton, setCanShowButton] = useState<boolean>(false)

  useEffect(() => {
    if (
      props.architecture?.serviceProvider === 'facebook' &&
      (!router.query?.state ||
        !Cookies.get('stateValue') ||
        router.query?.state !== Cookies.get('stateValue'))
    ) {
      setCanShowButton(true)

      return
    }
    if (props.architecture?.serviceProvider === 'instagram') {
      setCanShowButton(true)
    }

    if (
      router.query?.code &&
      props.architecture?.serviceApiPath &&
      props.architecture?.serviceProvider
    ) {
      setCanShowButton(false)

      fetcher
        .post(
          `${props.architecture.serviceApiPath}/${props.architecture.serviceProvider}/state`,
          { code: router.query.code },
        )
        .then(({ data }) => {
          if (data.status === 'error') {
            setCanShowButton(true)
            setSnackbar((prevState) => ({
              ...prevState,
              isOpen: true,
              message: `${props.architecture.serviceProvider} login failed`,
            }))

            return
          }

          if (data.status === 'login' && data.token) {
            Cookies.set('token', data.token)
            router.push('/')

            return
          }

          if (data.status === 'signup') {
            Cookies.remove('stateValue')
            Cookies.set('userSessionData', JSON.stringify(data.userSessionData))
            Cookies.set('isSignupNotCompleted', 'TRUE')
            router.push('/signup')

            return
          }

          setCanShowButton(true)
          setSnackbar((prevState) => ({
            ...prevState,
            isOpen: true,
            message: `${props.architecture.serviceProvider} login failed`,
          }))
        })
        .catch(() => {
          setCanShowButton(true)
          setSnackbar((prevState) => ({
            ...prevState,
            isOpen: true,
            message: `${props.architecture.serviceProvider} login failed`,
          }))
        })
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    router.query?.code,
    router.query?.state,
    props.architecture?.serviceApiPath,
    props.architecture?.serviceProvider,
  ])

  const handleClick = async () => {
    try {
      const response = await fetcher.post(
        `${props.architecture?.serviceApiPath}/${props.architecture?.serviceProvider}/login`,
      )
      if (
        response.status === 200 &&
        response.data?.loginURL &&
        ((props.architecture?.serviceProvider === 'facebook' &&
          response.data?.stateValue) ||
          props.architecture?.serviceProvider === 'instagram')
      ) {
        Cookies.set('stateValue', response.data.stateValue)
        ;(window.location as any) = response.data.loginURL
      }
    } catch {
      setSnackbar((prevState) => ({
        ...prevState,
        isOpen: true,
        message: `${props.architecture.serviceProvider} login failed`,
      }))
    }
  }

  if (!canShowButton) {
    return null
  }

  return (
    <div className={classes.contentStyle}>
      <Typography variant="body2" className={classes.text}>
        {props.translation.loginHelperText}
      </Typography>
      <Button
        variant="contained"
        className={classes.button}
        color="primary"
        onClick={handleClick}
        fullWidth={true}
      >
        {props.translation.loginButton}
      </Button>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentStyle: {
      maxWidth: '800px',
      width: '90vw',
      margin: '0 auto',
    },
    button: {
      height: '65px',
      marginTop: theme.spacing(5),
      letterSpacing: 'initial',
    },
    text: {
      marginTop: theme.spacing(3),
      letterSpacing: 'initial',
    },
  }),
)

const mapStateToProps = (state: IStore) => ({
  architecture: state.architecture,
  translation: state.translation,
})

export default connect(mapStateToProps)(Login)
